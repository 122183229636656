<template>
  <div>
    <div class="my-6 flex items-center justify-between">
      <div>
        <h3 class="font-bold">
          Editar item > <span class="text-primary">{{ data.titulo }}</span>
        </h3>

        <span class="text-sm text-gray-500"
          >Verifique as informações abaixo e clique no botão <b>Salvar</b> para
          confirmar as modificações feitas
        </span>
      </div>
      <div class="flex justify-end">
        <Button @click="edit" color="primary" text="Salvar" />
      </div>
    </div>

    <div class="card shadow-sm border p-8 bg-white">
          <div class="flex flex-col lg:flex-row justify-center">

          <div class="w-full p-2">
            <Input type="date" v-model="data.data" label="Data" />
          </div>

          <div class="w-full p-2">
              <Input
                v-model="data.descricao"
                type="default"
                label="Detalhes"
                placeholder="Detalhes"
              />
            </div>

          </div>
          <div class="flex flex-col lg:flex-row justify-center">
              <div class="w-full p-2">
              <Input
                type="money"
                v-model="data.valor"
                label="Valor"
                placeholder="Valor"
              />
               </div>

              <div class="w-full p-2">
                <Input
                  type="select"
                  v-model="data.id_pagamento_tipo"
                  label="Tipo de Pagamento"
                  placeholder="Tipo de Pagamento"
                  :data="tipoPag"
                />
              </div>

          <div class="w-full p-2">
            <Input
              name="pix"
              type="default"
              v-model="data.pix"
              label="Pix"
              placeholder="Pix"
              :data="tipoPag"
            />
          </div>
        </div>


      <div class="flex flex-col lg:flex-row justify-center"> 
             <div class="w-full p-2">
            
          <button
            class="
              bg-primary
              rounded-md
              shadow-md
              transform
              hover:scale-105
              px-10
              w-full
              py-2
              flex
              justify-center
              items-center
              font-bold
            "
          >
            <label class="text-white" for="logo">Trocar </label>
            <i class="ml-3 text-white gg-file"></i>
            <input
              @change="changeImage"
              class="absolute opacity-0 bton"
              id="logo"
              type="file"
              style="bottom: -35px; left: -0px"
            />
          </button>
          
           <button
              v-if="response"
            class="
              bg-primary
              rounded-md
              shadow-md
              transform
              hover:scale-105
              px-10
              py-2
              my-3
              w-full
              flex
              justify-self-end
              justify-center
              items-center
              font-bold
            "
            @click ="() => downloadPDF(tempFile)"
          >
            <label class="text-white">Baixar</label>
            <i class="ml-3 text-white gg-software-download"></i>
          </button>
          
          
          <button
            v-if="response"
            class="
              bg-primary
              rounded-md
              shadow-md
              transform
              hover:scale-105
              px-7
              py-2
              w-full
              my-3
              flex
              justify-self-end
              justify-center
              items-center
              font-bold
            "
            @click ="() => visualiarComprovante(tempFile)"
          >
            <label class="text-white mx-3">Visualizar</label>
            <i class="ml-3 text-white gg-read"></i>
          </button>
          
          
        </div>
      </div>
      </div>
    

     <hr class="my-5" />
     <div class="card shadow-sm border p-8 bg-white" v-if="dadosBancarios">
      <div class="flex flex-col lg:flex-row justify-center">
        <div class="w-full p-2">
          <Input 
            type="default"
            placeholder="Banco"
            label="Banco"
            v-model="dadosBancarios.banco"
          />
        </div>
        
        <div class="w-full p-2">
          <Input 
            type="default"
            placeholder="Agência"
            label="Agência"
            v-model="dadosBancarios.agencia"
          />
        </div>
        
        <div class="w-full p-2">
          <Input 
            type="select"
            placeholder="Tipo de Conta"
            label="Tipo de Conta"
            v-model="dadosBancarios.tipo_conta"
            :data="tipoConta"
          />
        </div>

      </div>

      <div class="flex flex-col lg:flex-row justify-center">
        <div class="w-full p-2">
          <Input 
            type="default"
            placeholder="Número da Conta"
            label="Número da Conta"
            v-model="dadosBancarios.numero_conta"
          />
        </div>
        
        <div class="w-full p-2">
          <Input 
            type="default"
            placeholder="Número da Conta Digito"
            label="Número da Conta Digito"
            v-model="dadosBancarios.numero_conta_digito"
          />
        </div>
        
        <div class="w-full p-2">
          <Input 
            type="default"
            placeholder="Nome do Titular"
            label="Nome do Titular"
            v-model="dadosBancarios.nome_titular"
          />
        </div>

      </div>

      <div class="flex flex-col lg:flex-row justify-center">
        <div class="w-full p-2">
          <Input 
            type="default"
            placeholder="CPF_CNPJ"
            label="CPF-CNPJ"
            v-model="dadosBancarios.cpf_cnpj"
          />
        </div>
        
        <div class="w-full p-2">
          <Input 
            type="select"
            placeholder="Tipo de Pessoa"
            label="Tipo de Pessoa"
            :data="tipoPessoa"
            v-model="dadosBancarios.tipo_pessoa"
          />
        </div>
      </div>
     
     </div>

  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { GET, PUT } from "../../services/api";
import { toDate, toDateUS } from "../../services/formater";
import { inject, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEmpty } from "../../services/validate";

export default {
  name: "EditarFinanceiro",
  components: {
    Input,
    Button,
  },

  data() {
    return {
      isMobile: false,
    };
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },
  setup() {
    const route = useRoute();

    const router = useRouter();
    const loader = inject("loading");
    const alert = inject("alert");
    const modal = inject("modal");
    const data = ref({});
    const profiles = ref([]);
    const tipoPag = ref([]);
    const preview = ref(null);
    const tempFile = ref();
    const response = ref();
    const dadosBancarios = ref({})
    const tipoPessoa = ref([]);
    const tipoConta = ref([]);

    onMounted(async () => {
      loader.open();

      const recept = { ...(await GET(`pagamento/${route.params.id}`)) };
  
      data.value = recept[0];
      const perm = await GET("pagamento_tipo");

      perm.forEach((p) => tipoPag.value.push({ value: p.id, label: p.tipo }));

      const date = data.value.data.split("T", 2);

      data.value.data = date[0];


      const au2x = { ...(await GET(`pagamento_dados_bancarios/${route.params.id}/pagamento`)) };

      dadosBancarios.value =  au2x[0]

      //console.log(dadosBancarios.value)


      tipoPessoa.value = [ { label: "Juridica", value:'j'}, { label: "Fisica", value:'f'}]
      tipoConta.value = [ { label: "Corrente", value:'cc'}, { label: "Pupança", value:'poupanca'}]


      
      tempFile.value =  `https://santocartao-files.s3.amazonaws.com/meuevento/comprovantes/${data.value.comprovante}`;
      response.value =  await fetch(`https://santocartao-files.s3.amazonaws.com/meuevento/comprovantes/${data.value.comprovante}`);

      const dinheiro = "R$ " + data.value.valor ;

      const aux = dinheiro.split(" ")
      aux[1] = aux[1].replace(".",",")

      aux[1].match(",")? 
      
      (aux[1].length - aux[1].match(",").index) == 2 ? aux[1] = aux[1] + "0" : aux[1] 
      
      : aux[1] = aux[1] + "," + "00"

      data.value.valor = "R$ " + aux[1];

      loader.close();
    });

    const setPreview = (profile) => {
      preview.value = profile;
    };

    const edit = async () => {

      const valorFloat = data.value.valor.split(" ", 2);
      valorFloat[1] = valorFloat[1].replace(".", "");
      data.value.valor = valorFloat[1].replace(",", ".");
      
      const object = {... data.value };

        delete object.id;
        delete object.comprovante;

       if(typeof tempFile.value != "string")
       object.file = tempFile.value;

       
        if(data.value.descricao == "" || data.value.descricao == null || data.value.descricao == undefined)
        {
          delete object.descricao
          data.value.descricao;
        }

      if(data.value.pix == "" || data.value.pix == null || data.value.pix == undefined)
        {
          delete object.pix
        }


      const validate = isEmpty(object);
      if (validate) {
        alert.open("Atenção!", validate, "warning");
      } else {
        modal.open(
          "Atenção",
          "Deseja realmente editar esse item?",
          "warning",
          "Sim",
          async () => {
            try {
            loader.open();

            const objRequest = new FormData();
            const item = object;
            for (const key in item) {
              objRequest.append(key, item[key]);
            }
              await PUT(`pagamento/${route.params.id}`, objRequest);


              if(dadosBancarios.value){

                const body = { ... dadosBancarios.value }

                

                delete body.id

                //console.log(body)
                 await PUT(`pagamento_dados_bancarios/${dadosBancarios.value.id}`, body);
              }
             
             alert.open(
                "Sucesso!",
                `O item foi editado com sucesso!`,
                "success"
              );
              loader.close();
              router.push("/financeiro");
            } catch (e) {
              loader.close();
              alert.open("Atenção!", e.message, "warning");
            }
          },
          modal.close()
        );
      }
    };

     const readURL = (file) => {
      

      if (typeof file == "string") {
        
        return file;
      }

      return window.URL.createObjectURL(file);
    };

    const changeImage = (event) => {
      const obj = {
        "id_evento": data.value["id_evento"],
        "id_pagamento_tipo": data.value["id_pagamento_tipo"],
        data: data.value.data,
        descricao: data.value["descricao"],
        valor: data.value.valor,
      }
      data.value = obj;
      tempFile.value =  event.target.files[0]
    }

    watch(()=> response.value, ()=>{
      if(response.value.status == 404)
        alert.open("Atenção!","Não existe comprovante para esse pagamento", "warnning")
    } )

    const downloadPDF =  (file) =>
    {

      if(typeof file !="string")
      {
        //download file
        const url = window.URL.createObjectURL(file);
        const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              // the filename you want
              a.download = file.name;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
      } else if(typeof file =="string"){
        fetch(file)
          .then(resp => resp.blob())
          .then(blob => {

              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              // the filename you want
              a.download = "Comprovante_";
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
          })
          .catch(() =>alert.open("Error", "Não foi possivel recuperar o comprovante","danger"));
      }
      else{
        alert.open("Atenção!","Selecione um comprovante primerio","warnning")
      }
        
    }

    const visualiarComprovante = (file) =>{
      if(typeof file !="string"){
        const urlFile =  window.URL.createObjectURL(file);

        window.open(urlFile)
      }else if(typeof file =="string"){
        fetch(file)
          .then(resp => resp.blob())
          .then(blob => {
      
            const url = window.URL.createObjectURL(blob);
            window.open(url)
          })
          .catch(() =>alert.open("Error", "Não foi possivel recuperar o comprovante","danger"));
      }
      else
      {
        alert.open("Atenção!","Não existe comprovante para visualizar","warning")
      }
    }
    

    return { edit, data, profiles, toDate, setPreview, preview, tipoPag,changeImage,readURL,tempFile , downloadPDF, response,
     visualiarComprovante
    ,dadosBancarios, tipoPessoa,tipoConta };
  },


};
</script>

<style>
.btn {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.bton{
    z-index: 5;
    width: 680px;
    height: 75px;
}

input[type="file"]::-webkit-file-upload-button{
    height:40px;
    }
</style>